import { parse } from "date-fns";
import { IVVejledning } from "../classes/IVVejledning";
import { IIVSetupInfo } from "../controls/IVInfoBox";
import { IIVSettings } from "../interfaces/IIVSettings";

export class Data {
    //#region properties
    
    private static _lastUpdate : number = Date.now();
    public static get lastUpdate() : number {
        return this._lastUpdate;
    }
    public static set lastUpdate(v : number) {
        this._lastUpdate = v;
    }
    

    private static _IVVejledninger : Array<IVVejledning> = [];
    public static get IVVejledninger() : Array<IVVejledning> {
        let test = Date.now() - Data.lastUpdate;
        if (test > 10000) {
            Data.lastUpdate = Date.now();
            Data.Get().then(v=>{
            }).catch(e=>{
                alert(e);
            });
        }
        return this._IVVejledninger;
    }
    public static set IVVejledninger(v : Array<IVVejledning>) {
        this._IVVejledninger = v;
    }
    
    
    private static _Settings : IIVSettings;
    public static get Settings() : IIVSettings {
        return this._Settings;
    }
    public static set Settings(v : IIVSettings) {
        this._Settings = v;
    }
      
    private static _Error : { Message: string } = { Message: "" };
    public static get Error() : { Message: string } {        
        this.GetErrors().then(x => {
            
        })
        return this._Error;
    }
    public static set Error(v : { Message: string }) {
        this._Error = v;
    }
    
    private static _Pages : any = {};
    public static get Pages() : any {
        return this._Pages;
    }
    public static set Pages(v : any) {
        this._Pages = v;
    }
    
    //#endregion

    //#region lifecycle
    public constructor() {

    }
    //#endregion

    //#region methods
    public static Get(): Promise<Array<IVVejledning>> {
        console.log("DDDDDDDDDDD");
        let fileName = `ivvejledninger.json?${new Date()}`
        return new Promise<Array<IVVejledning>>((resolve, reject) => {
            fetch(fileName)
            .then(response => response.json())
            .then(data => {
                data.forEach((element: any) => {
                    element["IVCardFieldApprovedDate"] = parse(element["IVCardFieldApprovedDate"] as string, 'dd-MM-yyyy HH:mm:ss', new Date());
                });
                this.IVVejledninger = data.sort((a: any, b: any) => {
                    let returnValue = 0;
                    if (a["IVCardFieldSubstanceInfo1"] > b["IVCardFieldSubstanceInfo1"]) {
                        returnValue = -1;
                    } else {
                        returnValue = 1;
                    }
        
                    returnValue = returnValue * -1;

                    return returnValue;
                });
                resolve(this.IVVejledninger);
            })
            .catch(error => {
                reject(error);
            })
        });
    }


    public static Getsettings(): Promise<IIVSettings> {
        let fileName = `settings.json?${new Date()}`

        return new Promise<IIVSettings>((resolve, reject) => {
            fetch(fileName)
            .then(response => response.json())
            .then(data => {
                this.Settings = data;
                resolve(this.Settings);
            })
            .catch(error => {
                reject(error);
            })
        });
    }

    public static GetPages(): Promise<IIVSettings> {
        let fileName = `sider.json?${new Date()}`

        return new Promise<IIVSettings>((resolve, reject) => {
            fetch(fileName)
            .then(response => response.json())
            .then(data => {
                data.forEach((page: {Title: string, Body: string}) => {
                    this.Pages[page.Title] = page.Body;
                });
                resolve(this.Pages);
            })
            .catch(error => {
                reject(error);
            })
        });
    }

    public static GetErrors(): Promise<{ Message: string }> {
        let fileName = `error.json?${new Date()}`

        return new Promise<{ Message: string }>((resolve, reject) => {
            fetch(fileName)
            .then(response => response.json())
            .then((data: { Message: string }) => {
                this.Error = data;
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
        });
    }
    public static GetFieldSetting(_FieldName: string): IIVSetupInfo {
        let returnValue: IIVSetupInfo = {
            Name: _FieldName,
            Title: _FieldName
        };

        if (this.Settings !== undefined) {
            if (this.Settings.formFields.filter(formField => formField.Name === _FieldName).length > 0) {
                returnValue = this.Settings.formFields.filter(formField => formField.Name === _FieldName)[0];
            }                
        }
        return returnValue;
    }

    //#endregion
}