import { CommandBar, ICommandBarItemProps, MessageBar } from "@fluentui/react";
import * as React from "react";
import { IVList } from "./IVList";
import { IVItem } from "./IVItem";
import { LocationHash } from "../classes/hash";
import { Data } from "../services/data";
import { IVVejledning } from "../classes/IVVejledning";
import { rootCertificates } from "tls";
import { getDate } from "date-fns";

export interface IVAppState {}
export class IVApp extends React.Component<{}, IVAppState> {
    //#region properties
    private _items: ICommandBarItemProps[] = [ ];
    //#endregion

    //#region lifecycle
    constructor(props: {}) {
        super(props);
        this.HashState = this.HashState.bind(this);

        if (window.location.search !== "") {
            window.location.hash = window.location.search.replace("?", "#");
            window.location.search = ""
        }
        let hash: LocationHash = new LocationHash();
        if (hash.hashes.atcfilter !== undefined || hash.hashes.filter !== undefined) {
            hash.hashes.showlist = 1;
            hash.Set();
        }
    }

    componentDidMount(): void {
        window.addEventListener('hashchange', this.HashState, false);
        window.addEventListener('resize', (a) => {
            this.setState({});
            console.log(a)
        }, false);

        this.GetData();
    }

    componentWillUnmount(): void {
        window.removeEventListener('hashchange', this.HashState, false);
        window.removeEventListener('resize', (a) => {console.log(a)});        
    }
    public render(): React.ReactElement<{}> {
        let hash: LocationHash = new LocationHash();
        let card: React.ReactElement = <div style={{padding: "10px;"} }><div dangerouslySetInnerHTML={{ __html: Data.Pages.Forside}}></div></div>;
        let items: Array<ICommandBarItemProps> = [];
        let farItems: Array<ICommandBarItemProps> = [];
        if (Data.Settings !== undefined) {
            try {
                Data.Settings.menu.items.forEach(item => {
                    items.push(this.ConvertMenuItem(item));
                });
            } catch (error) {
                
            }
            try {
                Data.Settings.menu.farItems.forEach(item => {
                    farItems.push(this.ConvertMenuItem(item));
                });
            } catch (error) {
                
            }
            
        }
        if (hash.hashes.itemid) {
            let ivItems = Data.IVVejledninger;
            let item = ivItems.filter((ivVejledning:IVVejledning) => ivVejledning.ID == hash.hashes.itemid)[0];
            
            card = <IVItem ListItem={item}/>
        } else if (hash.hashes.showlist) {
            card = <IVList items={Data.IVVejledninger}/>
        } else if (hash.hashes.side) {
            card = <div style={{padding: "10px;"} } dangerouslySetInnerHTML={{ __html: Data.Pages[hash.hashes.side]}}></div>
        }

        if (hash.hashes.login) {
            let urlOpen = ((window as any)["loginUrl"]);
            if (hash.hashes.admin) {
                urlOpen += "/_layouts/15/Amgros.IVCard.ModernBrand/login.aspx#";
            } else {
                urlOpen += "#"
            }

            if (hash.hashes.itemid) {
                urlOpen = urlOpen + "&itemid=" + hash.hashes.itemid;
            }
            if (hash.hashes.filter) {
                if (!hash.hashes.itemid) {
                    urlOpen = urlOpen + "&filter=" + hash.hashes.filter;
                }
            }
            if (hash.hashes.atcfilter) {
                urlOpen = urlOpen + "&atcfilter=" + hash.hashes.atcfilter;
            }
            window.open(
                urlOpen,
                '_blank' // <- This is what makes it open in a new window.
              );
              delete hash.hashes.login;
            delete hash.hashes.admin;
            hash.Set();
        }

        let t = Data.Error.Message ;
        let showMsg: boolean = (Data.Error.Message !== "");
        return <span>
            {showMsg &&
            <MessageBar hidden={!showMsg}>
                {Data.Error.Message}
            </MessageBar>
            }
            <CommandBar items={items} farItems={farItems} styles={{root: {backgroundColor:"#d5dde9"}}}/>
            {card}
        </span>;
    }
    //#endregion

    //#region methods

    private GetData() {
        Data.GetErrors().then(error => {
            Data.Getsettings().then(settings => {
                console.log(settings)
                Data.GetPages().then(pages => {
                    console.log(pages);
                    Data.Get().then(IVVejledninger => {
                        console.log(IVVejledninger)
                        this.setState({});
                    }).catch(error => {
                        console.log(error);
                        alert("1" + error);
                    });
                }).catch(error => {
                    console.log(error);
                    alert("2" + error);
                });
            }).catch(error => {
                console.log(error);
                this.GetData();
            });
        }).catch(error => {
            console.log(error);
            this.GetData();
        });
    }
    HashState(hashChange: HashChangeEvent) { 
        console.log(hashChange);
        this.setState({});
    }

    ConvertMenuItem(item: ICommandBarItemProps): ICommandBarItemProps {
        let returnValue: ICommandBarItemProps = item;
        item.buttonStyles = { root: {backgroundColor:"#d5dde9"}}
        item.onClick = () => {
            switch ((item as any).hash) {
                case "":
                    window.location.href = window.location.origin;
                    break;
            
                case "#":
                    window.location.hash = "";
                    break;

                case "&":
                    if (window.location.hash ==="") {
                        window.location.hash += (item as any).hash;
                        
                    } else {
                        window.location.hash += (item as any).hash;
                    }
                    break;
                default:
                    if ((item as any).hash.indexOf('&') === 0) {
                        window.location.hash += (item as any).hash;                        
                    } else {
                        window.location.hash = (item as any).hash;
                    }
                    break;
            }
        }
        return returnValue;
    }
    //#endregion
}